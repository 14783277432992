/* eslint-disable react/react-in-jsx-scope */
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
} from 'reactstrap';

import owSauv from '../../images/ow-sauv-2021.jpg';
import owPinot from '../../images/ow-pinot-2021.jpg';
import saleGrapes from '../../images/sale-grapes.png';

const OurWines = () => {
  return (
    <Container fluid>
      <Row className='mt-3 justify-content-center'>
        <Col className='bg-owHeader text-center'>
          <h3>Our Wines</h3>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col xs='0' sm='1' className='bg-owSauv' />
        <Col xs='12' sm='10'>
          <Row>
            <Col sm='6' className='bg-owSauv'>
              <Card body className='mt-4 text-center'>
                <CardTitle tag='h5'>Sauvignon Blanc 2021</CardTitle>
                <CardSubtitle className='bg-owPrice' tag='h6'>
                  $39
                </CardSubtitle>
                <img
                  alt='Sauvignon Blanc 2021'
                  src={owSauv}
                  className='img-fluid mx-auto d-block rounded img-story'
                />
                <CardText>
                  Vibrant and smooth, our Sauvignon Blanc 2021 is a delicate
                  combination of pineapple, lychee and mango.
                </CardText>
                <a
                  href='https://lbuvas.securewinemerchant.com/store'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button>Order</Button>
                </a>
              </Card>
            </Col>
            <Col sm='6' className='bg-owPinot'>
              <Card body className='mt-4 text-center'>
                <CardTitle tag='h5'>Pinot Noir 2021</CardTitle>
                <CardSubtitle className='bg-owPrice' tag='h6'>
                  $65
                </CardSubtitle>
                <img
                  alt='Pinot Noir 2021'
                  src={owPinot}
                  className='img-fluid mx-auto d-block rounded img-story'
                />
                <CardText>
                  With a silky texture and juicy acidity, our Pinot Noir 2021
                  has notes of strawberries, brown sugar and dark chocolate.
                </CardText>
                <a
                  href='https://lbuvas.securewinemerchant.com/store'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button>Order</Button>
                </a>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs='0' sm='1' className='bg-owPinot' />
      </Row>
      <Row className='justify-content-center'>
        <Col xs='12' className='bg-owOrder mt-3 text-center'>
          <img alt='our uvas' src={saleGrapes} />
        </Col>
      </Row>
    </Container>
  );
};

export default OurWines;
