/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col, Card, CardTitle, CardText } from 'reactstrap';

import story from '../../images/story-01.jpg';
import grapes from '../../images/grapes.png';

const Overview = () => {
  return (
    <Container fluid id='overview'>
      <Row className='mt-3'>
        <Col xs='1' />
        <Col xs='10'>
          <Row>
            <Col>
              <Card className='story'>
                <CardTitle tag='h5'>Our Story</CardTitle>
                <CardText>
                  LB Uvas was created over the shared experience of fine wines,
                  California cuisine and the company of 10+ year friendships.
                  During the crucial times of the 2020 pandemic, Joshua Sanchez,
                  Zach Marsh and Hank Marsh constructed the diversity of life in
                  a bottle. With much importance to connect with others, this
                  wine represents the lifestyle approach of new millennials.
                  With the mentorship and credentials from Hank Marsh, the three
                  were able to come together and incorporate their passion for
                  wine from a entrepreneurial background. With roots in Long
                  Beach, we wanted to bring this culture back home one glass at
                  a time.
                </CardText>
              </Card>
              <div className='align-items-center mt-3'>
                <img
                  alt='our uvas'
                  src={story}
                  className='img-fluid mx-auto d-block rounded img-story'
                />
              </div>
            </Col>
          </Row>
          <Row className='align-items-center story-footer'>
            <Col xs='12'>
              <img
                alt='our uvas'
                src={grapes}
                className='mx-auto d-block'
                width='60'
              />
            </Col>
          </Row>
        </Col>
        <Col xs='1' />
      </Row>
    </Container>
  );
};

export default Overview;
