/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col, Button } from 'reactstrap';
import { Textfit } from 'react-textfit';

import saleGrapes from '../../images/sale-grapes.png';
import grapePreview from '../../images/video_preview.jpg';
import grapeVideo from '../../video/iStock-1044279946.mp4';

const Main = () => {
  return (
    <Container fluid id='main'>
      <Row className='main align-items-center'>
        <Col>
          <video
            src={grapeVideo}
            autoPlay
            playsInline
            muted
            loop
            poster={grapePreview}
          />
          <div className='mainText'>
            <h1 className='text-lg-start text-center ps-lg-5'>
              <span className='LB'>LB</span>
              <span className='Uvas'>Uvas</span>
            </h1>
            <h3 className='text-lg-start text-center ps-lg-5'>
              <Textfit mode='single' max='24'>
                2021 Sauvignon Blanc Available Now!
              </Textfit>
            </h3>
          </div>
        </Col>
      </Row>

      <Row className='order justify-content-center'>
        <Col xs='12' sm='10'>
          <p className='missionStatement mb-0'>
            <span className='missionStatementLB'>LB</span>
            <span className='missionStatementUvas'>Uvas</span> is a result of a
            relationship coming together to fuse their passions of wine and
            business. We enjoy a lifestyle of incorporating the two and believe
            when you have a good life and successful business, you’ll find
            quality wine.
          </p>
          <p className='mt-0'>
            <strong> - Hank, Zach, & Joshua</strong>
          </p>
        </Col>
        <Col xs='12' sm='10' className='mt-3'>
          Our 2021 Sauvignon Blanc is ready!
          <br />
          <a
            href='https://lbuvas.securewinemerchant.com/store'
            target='_blank'
            rel='noreferrer'
          >
            <Button>ORDER NOW</Button>
          </a>
          <br />
          <img alt='our uvas' src={saleGrapes} />
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
