/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Card,
  CardTitle,
} from 'reactstrap';

import contactCork from '../../images/contact-cork.jpg';

const Contact = () => {
  const recaptchaRef = React.createRef();

  // Input Change Handling
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  // Input Error Handling
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  // Server State Handling
  const handleOnSubmit = (event) => {
    event.preventDefault();

    // reset formError
    setFormError(false);

    // setup variable for form validation
    let formValid = true;

    // check if name is empty
    if (inputs.name.length === 0) {
      // set nameError to true && formValid to false
      setNameError(true);
      formValid = false;
    } else {
      setNameError(false);
    }

    // check if email is valid
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        inputs.email
      )
    ) {
      // set emailError to true && formValid to false
      setEmailError(true);
      formValid = false;
    } else {
      setEmailError(false);
    }

    // check if subject is empty
    if (inputs.subject.length === 0) {
      // set subjectError to true && formValid to false
      setSubjectError(true);
      formValid = false;
    } else {
      setSubjectError(false);
    }

    // check if message is empty
    if (inputs.message.length === 0) {
      // set messageError to true && formValid to false
      setMessageError(true);
      formValid = false;
    } else {
      setMessageError(false);
    }

    //form input is valid, submit to FormBold
    if (formValid) {
      axios({
        method: 'POST',
        url: 'https://formbold.com/s/3VpgJ',
        data: {
          ...inputs,
          'g-recaptcha-response': recaptchaRef.current.getValue(),
        },
      })
        .then((r) => {
          setFormSubmitted(true);
        })
        .catch((r) => {
          setFormError(true);
        });
    }
  };

  return (
    <Container fluid id='contact'>
      <Row className='contact'>
        <Col xs='1' />
        <Col xs='10'>
          <Row>
            <Col sm='6' className='align-items-center p-0'>
              <img
                alt='Contact Us'
                src={contactCork}
                className='img-fluid mx-auto d-block rounded img-story'
              />
            </Col>
            <Col sm='6' className='p-0'>
              <Card body className='h-100'>
                <CardTitle>
                  <b>Contact Us</b>
                </CardTitle>
                {!formSubmitted && (
                  <Form onSubmit={handleOnSubmit}>
                    <FormGroup row>
                      <Col sm={12}>
                        <Input
                          invalid={nameError}
                          onChange={handleOnChange}
                          value={inputs.name}
                          id='name'
                          type='text'
                          name='name'
                          placeholder='name'
                        />
                      </Col>
                      {nameError && (
                        <Col>
                          <p className='text-danger'>
                            <small>Please enter a name</small>
                          </p>
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12}>
                        <Input
                          invalid={emailError}
                          onChange={handleOnChange}
                          value={inputs.email}
                          id='email'
                          type='email'
                          name='email'
                          placeholder='email'
                        />
                      </Col>
                      {emailError && (
                        <Col>
                          <p className='text-danger'>
                            <small>Please enter a valid email address</small>
                          </p>
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12}>
                        <Input
                          invalid={subjectError}
                          onChange={handleOnChange}
                          value={inputs.subject}
                          id='subject'
                          type='text'
                          name='subject'
                          placeholder='subject'
                        />
                      </Col>
                      {subjectError && (
                        <Col>
                          <p className='text-danger'>
                            <small>Please enter a subject</small>
                          </p>
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12}>
                        <Input
                          invalid={messageError}
                          onChange={handleOnChange}
                          value={inputs.message}
                          id='message'
                          type='textarea'
                          name='message'
                          placeholder="let us know what's on your mind"
                          rows='4'
                        />
                      </Col>
                      {messageError && (
                        <Col>
                          <p className='text-danger'>
                            <small>Please enter a message</small>
                          </p>
                        </Col>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey='6LfjU4IjAAAAAGlJayW7-GQmYPKj8FPu4P4Ia-Vf'
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Button type='submit'>SUBMIT</Button>
                      </Col>
                    </FormGroup>
                    {formError && (
                      <Row>
                        <Col>
                          <strong className='text-danger'>
                            There was an error submitting the form, please try
                            again.
                          </strong>
                        </Col>
                      </Row>
                    )}
                  </Form>
                )}
                {formSubmitted && (
                  <Row>
                    <Col>
                      <h4>Thank you for getting in touch!</h4>
                      <p>
                        We appreciate you contacting{' '}
                        <span className='footerLB'>LB</span>
                        <span className='footerUvas'>Uvas</span>. One of our
                        colleagues will get back in touch with you soon!
                      </p>
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs='1' />
      </Row>
    </Container>
  );
};

export default Contact;
