/* eslint-disable react/react-in-jsx-scope */
import React, { Component } from 'react';
import { Card, CardText, CardTitle, Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';

import availability from '../../images/carousel-availability.png';
import flavorsPinot from '../../images/carousel-flavors-pinot.png';
import flavorsSauv from '../../images/carousel-flavors-sauv.png';
import aromasPinot from '../../images/carousel-aromas-pinot.png';
import aromasSauv from '../../images/carousel-aromas-sauv.png';
import foodPinot from '../../images/carousel-food-pinot.png';
import foodSauv from '../../images/carousel-food-sauv.png';
import hills from '../../images/carousel-hills.png';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7500,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className='bg-carousel'>
        <Container>
          <Row>
            <Col xs='0' sm='2' md='3' />
            <Col>
              <div>
                <Slider {...settings}>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Availability'
                          src={availability}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardTitle tag='h5'>
                            Exclusive availability!
                          </CardTitle>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Pinot Noir is only available for order
                            through our site.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Flavors'
                          src={flavorsPinot}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Pinot Noir is a delicate blend of
                            strawberry, brown sugar and dark chocolate.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Flavors'
                          src={flavorsSauv}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Sauvignon Blanc is a sumptuous melange of
                            pineapple, lychee and mango.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Aromas'
                          src={aromasPinot}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Pinot Noir has aromas of wild berries,
                            cocoa powder and baking spices.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Aromas'
                          src={aromasSauv}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Sauvignon Blanc has aromas of orange peel,
                            lemon and nectarines.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Food Pairings'
                          src={foodPinot}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Pinot Noir pairs well with steak, lamb and
                            roast goose.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Food Pairings'
                          src={foodSauv}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Our 2021 Sauvignon Blanc pairs best with fresh
                            seafood, chicken and green vegetables.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                  <div>
                    <Row className='align-items-center'>
                      <Col xs='12' sm='6'>
                        <img
                          alt='Sta Rita Hills'
                          src={hills}
                          className='img-fluid mx-auto d-block img-carousel'
                        />
                      </Col>
                      <Col xs='12' sm='5'>
                        <Card body className='bg-carousel-card-01'>
                          <CardText className='bg-carousel-card-01-text'>
                            Harvested in September 2021, our grapes grew during
                            warm days and cool nights, yielding ripe flavors and
                            vibrant acidity.
                          </CardText>
                        </Card>
                      </Col>
                      <Col xs='0' sm='1' />
                    </Row>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col xs='1' sm='3' md='4' lg='5' />
          </Row>
        </Container>
      </div>
    );
  }
}
