/* eslint-disable react/react-in-jsx-scope */
import Header from '../Header/Header';
import Main from '../Main/Main';
import Carousel from '../Carousel/Carousel';
import OurWines from '../OurWines/OurWines';
import Overview from '../Overview/Overview';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';

const Layout = () => {
  return (
    <div>
      <Header />
      <Main />
      <Carousel />
      <OurWines />
      <Overview />
      <Contact />
      <Footer />
    </div>
  );
};

export default Layout;
