/* eslint-disable react/react-in-jsx-scope */
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <Container fluid className='footer'>
      <Row className='align-items-center text-center'>
        <Col>
          <div>
            <span className='footerLB'>LB</span>
            <span className='footerUvas'>Uvas</span> ~ 2022 All rights reserved
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
