/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-scroll';

// eslint-disable-next-line no-unused-vars
const Header = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color='dark' dark expand='sm' fixed='top'>
        <Link to='main' spy={false} smooth={true} duration={500}>
          <NavbarBrand>
            <span className='LB'>LB</span>
            <span className='Uvas'>Uvas</span>
          </NavbarBrand>
        </Link>
        <NavbarToggler onClick={toggleNavbar} className='mr-2' />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar className='ms-auto'>
            <NavItem>
              <NavLink
                href='https://lbuvas.securewinemerchant.com/store'
                target='_blank'
                rel='noreferrer'
              >
                Order
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link to='overview' spy={false} smooth={true} duration={500}>
                  Our Story
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Link to='contact' spy={false} smooth={true} duration={500}>
                  Contact
                </Link>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
